export const WEEKDAY_TYPE_MAP = {
  [0]: 'sunday',
  [1]: 'monday',
  [2]: 'tuesday',
  [3]: 'wednesday',
  [4]: 'thursday',
  [5]: 'friday',
  [6]: 'saturday',
} as const;

export const SCHEME_COURSE_STATUS_MAP = {
  [0]: 'active',
  [1]: 'inactive',
  [2]: 'cancelled',
} as const;

export const EQUALITY_OPERATOR = {
  IN: 0,
  NOT_IN: 1,
  BETWEEN: 2,
  FROM: 3,
  TO: 4,
  EQUALS: 5,
  NOT_EQUALS: 6,
  PREFIX: 7,
  SUFFIX: 8,
  CONTAINS: 9,
  REGEX: 10,
} as const;

export const LOGICAL_OPERATOR = {
  AND: 0,
  OR: 1,
};

export const SORT_DIRECTION = {
  ASCENDING: 0,
  DESCENDING: 1,
} as const;

export const SCHEME_SUBCATEGORY = {
  GROUP_COACHING: 0,
  HOLIDAY_CAMPS: 1,
  PRIVATE_LESSONS: 2,
  EVENTS: 3,
} as const;

export type SchemeSubCategory = (typeof SCHEME_SUBCATEGORY)[keyof typeof SCHEME_SUBCATEGORY];
