import React from 'react';

import Layout from 'src/components/layout/layout';
import SchoolsList from 'src/components/organisations/schools-list/schools-list';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';
import IsHighSchoolState from 'src/components/util-components/is-high-school-state';
import Redirect from 'src/components/util-components/redirect';
import ROUTES from 'src/utils/routes';

const ManageSchools: React.FC = () => {
  return (
    <Layout>
      <SEO title="Manage Schools" />
      <PrivateRoute>
        <IsHighSchoolState>
          <IsHighSchoolState.Render ifTrue>
            <SchoolsList />
          </IsHighSchoolState.Render>
          <IsHighSchoolState.Render ifFalse>
            <Redirect to={ROUTES.NOT_FOUND} />
          </IsHighSchoolState.Render>
        </IsHighSchoolState>
      </PrivateRoute>
    </Layout>
  );
};

export default ManageSchools;
